import React from "react";
import { Button } from "@mantine/core";
import { Link } from "react-router-dom";

export const buttonSizes = {
  small: "fit-content",
  medium: 172,
  large: 348,
  full: "100%",
};

const CustomButton = ({
  children,
  to,
  variant,
  size = "medium",
  fw,
  c,
  ...other
}) => {
  const btnVariant = variant ? variant : "filled";
  const btnWidth = buttonSizes[size];
  return (
    <Button
      component={to && Link}
      to={to}
      w="100%"
      variant={btnVariant}
      maw={btnWidth}
      size="md"
      px={12}
      {...other}
      fw={fw || 600}
      c={c || "#333"}
    >
      {children}
    </Button>
  );
};

export default CustomButton;
