import {
  Box,
  Container,
  Flex,
  Image,
  PasswordInput,
  Space,
  TextInput,
  Title,
} from "@mantine/core";
import Logo from "../asset/images/logo2.png";
import { useState } from "react";
import { Iconn } from "../components/Iconn";
import CustomButton from "../components/CustomButton";
import { colors } from "../theme/colors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleEmail = (e) => {
    setUsername(e.target.value.toLowerCase());
    localStorage.setItem("username", e.target.value);
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleClick = (e) => {
    setLoading(true);

    axios
      .post(
        "https://api.airtime.alitas.online/login",
        { username: username, password: password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(
        (response) => {
          console.log("Uspešno poslato!");
          setLoading(false);
          sessionStorage.setItem("authToken", response.data.data.token);
          navigate("/dashboard");
        },
        (error) => {
          console.error("Greška prilikom slanja zahteva:", error);
          setLoading(false);
          toast.error("Email or password is not valid");
        }
      );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  return (
    <Container size="xs">
      <Flex direction="column" justify="center" align="center" mt={50}>
        <Image src={Logo} maw={300} mah={180} mb={80} radius={20} />
        <Title order={1} mb={40} style={{ fontFamily: "cursive" }}>
          Sign In
        </Title>
        <Box miw="100%">
          <TextInput
            label="Username"
            placeholder="username"
            leftSection={<Iconn icon="email" size={19} />}
            value={username}
            onChange={handleEmail}
            onKeyDown={handleKeyDown}
          />
          <Space h="md" />
          <PasswordInput
            label="Password"
            placeholder="&#9679;&#9679;&#9679;&#9679;&#9679;"
            leftSection={<Iconn icon="lock" size={19} />}
            value={password}
            onChange={handlePassword}
            onKeyDown={handleKeyDown}
          />
          <Space h={40} />
          <Flex justify="center">
            <CustomButton
              variant="gradient"
              gradient={{
                from: colors.lightBlue,
                to: colors.darkBlue,
                deg: 50,
              }}
              c="white"
              onClick={handleClick}
              type="submit"
            >
              Sign in
            </CustomButton>
          </Flex>
        </Box>
      </Flex>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Container>
  );
};
