import { Flex, Grid, Loader, Text } from "@mantine/core";
import { Iconn } from "../../Iconn";

export const LiveData = ({ liveData, onlineTime }) => {
  if (!liveData || !onlineTime)
    return (
      <Flex py={50} justify="center">
        <Loader color="blue" />
      </Flex>
    );

  const live = liveData.data;

  return (
    <Grid pb={20} pl={7}>
      <Grid.Col span={{ base: 12, md: 6, lg: 3 }} p={0} pr={5}>
        <Flex align="center">
          <Text fw={500} fz="sm">
            Aggregated Status:
          </Text>
          <Iconn
            icon="dot"
            size={28}
            stroke={
              live.aggregated_status === "online"
                ? "#00e600"
                : live.aggregated_status === "suspended"
                ? "#e6b800"
                : "#ff0000"
            }
            strokeWidth={9}
          />
          <Text tt="capitalize" fw={600} fz="sm">
            {live.aggregated_status}
          </Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6, lg: 3 }} p={0} pr={5}>
        <Flex align="center" h={28}>
          <Text fw={500} fz="sm">
            Current Beam:
          </Text>
          &nbsp;
          <Text fw={600} fz="sm">
            {live.current_beam_name}
          </Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6, lg: 3 }} p={0} pr={5}>
        <Flex align="center" h={28}>
          <Text fw={500} fz="sm">
            Current Satelite:
          </Text>
          &nbsp;
          <Text fw={600} fz="sm">
            {live.current_satellite_name}
          </Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 6, lg: 3 }} p={0}>
        <Flex align="center" h={28}>
          <Text fw={500} fz="sm">
            Online Time:
          </Text>
          &nbsp;
          <Text fw={600} fz="sm">
            {onlineTime.data.formated_online}
          </Text>
        </Flex>
      </Grid.Col>
    </Grid>
  );
};
