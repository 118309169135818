import { Flex, Loader, LoadingOverlay } from "@mantine/core";
import { useGetShips } from "../hooks/useGetShips";
import "leaflet/dist/leaflet.css";
import { DashboardPage } from "../components/dashboard/DashboardPage";
import { useGetFleet } from "../hooks/useGetFleet";

export const Dashboard = () => {
  const { ships } = useGetShips();
  const { fleetDetails } = useGetFleet();

  if (!ships || !fleetDetails)
    return (
      <Flex py={50} justify="center">
        <Loader color="blue" />
      </Flex>
    );

  const shipsData = ships.data;
  const fleet = fleetDetails.data;

  return (
    <>
      <DashboardPage shipsData={shipsData} fleet={fleet} />
    </>
  );
};
