import { Alert, Flex, Grid, Text } from "@mantine/core";
import { Iconn } from "../../../Iconn";
import { colors } from "../../../../theme/colors";
import { SnrChart } from "./SnrChart";
import { LatencyChart } from "./LatencyChart";
import { ReceivedChart } from "./ReceivedChart";
import { TransmittedChart } from "./TransmittedChart";

export const Charts = ({
  snrData,
  latencyData,
  transmittedData,
  receivedData,
}) => {
  return (
    <>
      {snrData.data.length === 0 ||
      latencyData.data.length === 0 ||
      transmittedData.data.length === 0 ||
      receivedData.data.length === 0 ? (
        <Alert
          my={10}
          variant="light"
          color="red"
          icon={<Iconn icon="x" stroke="red" />}
        >
          Statistics is currently unavailable.
        </Alert>
      ) : (
        <>
          <Flex pt={40} pb={20} gap={10}>
            <Iconn icon="chart" stroke={colors.primaryBlue} />
            <Text fz={16}>Statistics</Text>
          </Flex>

          <Grid>
            <Grid.Col span={{ base: 12, lg: 6 }}>
              <SnrChart snrData={snrData} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 6 }}>
              <LatencyChart latencyData={latencyData} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 6 }}>
              <ReceivedChart receivedData={receivedData} />
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 6 }}>
              <TransmittedChart transmittedData={transmittedData} />
            </Grid.Col>
          </Grid>
        </>
      )}
    </>
  );
};
