import PropTypes from "prop-types";
import { Navigate } from "react-router-dom";

export const AuthGuard = ({ children }) => {
  const token = window.sessionStorage.getItem("authToken");

  if (!token) {
    return <Navigate to="/" replace />;
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
