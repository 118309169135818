import { useNavigate } from "react-router-dom";
import api from "../api";
import useSWR from "swr";

const fetcher = async () => {
  const data = await api.get("terminals/listing");

  return data;
};

export const useGetFleet = () => {
  const { data, error } = useSWR("fleet", fetcher);
  const navigate = useNavigate();
  if (error && error.response && error.response.status === 401) {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
    window.location.reload();
  }
  return {
    fleetDetails: data,
    isLoading: !error && !data,
    isError: error,
  };
};
