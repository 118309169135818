import { Box, Grid } from "@mantine/core";
import { ShipDetailPage } from "../components/monitoring/right-section/ShipDetailPage";
import { useParams } from "react-router-dom";
import useGetShipLiveData from "../hooks/useGetShipLiveData";
import useGetOnlineTime from "../hooks/useGetOnlineTime";
import { useGetFleet } from "../hooks/useGetFleet";
import { AccordionSection } from "../components/monitoring/AccordionSection";

export const Monitoring = () => {
  const { id } = useParams();
  const { liveData } = useGetShipLiveData(id);
  const { onlineTime } = useGetOnlineTime(id);
  const { fleetDetails } = useGetFleet();

  return (
    <Box px={{ base: 0, sm: 24 }}>
      <Grid justify="space-between">
        <Grid.Col span={{ base: 12, lg: 3 }}>
          <AccordionSection fleetDetails={fleetDetails} />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 9 }}>
          <ShipDetailPage liveData={liveData} onlineTime={onlineTime} />
        </Grid.Col>
      </Grid>
    </Box>
  );
};
