import { Accordion, Flex, Loader, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Iconn } from "../Iconn";
import { colors } from "../../theme/colors";

export const AccordionSection = ({ fleetDetails }) => {
  const navigate = useNavigate();

  if (!fleetDetails)
    return (
      <Flex py={50} justify="center">
        <Loader color="blue" />
      </Flex>
    );

  const fleet = fleetDetails.data;

  const handleShipClick = (terminalId, shipName) => {
    navigate(`/ship/${terminalId}`);
  };

  const items = fleet.map((item, index) => (
    <Accordion.Item key={index} value={item.fleet.fleet_name}>
      <Accordion.Control>{item.fleet.fleet_name}</Accordion.Control>
      <Accordion.Panel>
        {item.ships.map((ship, index) => (
          <Flex direction="column" key={index}>
            <Text
              style={{ cursor: "pointer" }}
              onClick={() => handleShipClick(ship.terminal.terminal_id)}
              key={ship.terminal.terminal_id}
              py={7}
            >
              {ship.ship_name}
            </Text>
          </Flex>
        ))}
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <>
      <Flex gap={10} justify="center" py="md" mt={16}>
        <Text fw={500} fz={18}>
          Fleet List
        </Text>
        <Iconn icon="ship" stroke={colors.primaryBlue} />
      </Flex>
      <Accordion
        variant="contained"
        chevronSize={24}
        radius={15}
        styles={{
          root: {
            border: 0,
          },
          item: {
            border: 0,
          },
          control: {
            border: "1px solid #ECECEC",
            background: "#FFF",
            boxShadow: "0px 4px 4px 0px rgba(85, 60, 31, 0.02)",
          },
        }}
      >
        {items}
      </Accordion>
    </>
  );
};
