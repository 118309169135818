import { Accordion, Flex, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";

export const AccordionGlobalSection = ({ fleet }) => {
  const navigate = useNavigate();

  const handleShipClick = (terminalId) => {
    navigate(`/ship/${terminalId}`);
  };

  const items = fleet.map((item, index) => (
    <Accordion.Item key={index} value={item.fleet.fleet_name}>
      <Accordion.Control>{item.fleet.fleet_name}</Accordion.Control>
      <Accordion.Panel>
        {item.ships.map((ship, index) => (
          <Flex direction="column" key={index}>
            <Text
              style={{ cursor: "pointer" }}
              onClick={() => handleShipClick(ship.terminal.terminal_id)}
              key={ship.terminal.terminal_id}
              py={7}
            >
              {ship.ship_name}
            </Text>
          </Flex>
        ))}
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <>
      <Flex
        mt={15}
        gap={10}
        justify="center"
        py="md"
        pos="relative"
        bg="#f2f2f2"
        style={{ borderRadius: "15px" }}
        direction="column"
      >
        <Text
          fw={600}
          fz={20}
          // ml={80}
          py={15}
          ta="center"
        >
          Fleet List
        </Text>
        {/* <Image
          src={ship}
          mah={150}
          maw={150}
          pos="absolute"
          top={-70}
          left={10}
        /> */}

        <Accordion
          mb={{ base: 32, sm: 0 }}
          variant="contained"
          chevronSize={24}
          radius={0}
          styles={{
            root: {
              border: 0,
            },
            item: {
              border: 0,
            },
            control: {
              border: "1px solid #ECECEC",
              background: "#f2f2f2",
              boxShadow: "0px 4px 4px 0px rgba(85, 60, 31, 0.02)",
            },
          }}
        >
          {items}
        </Accordion>
      </Flex>
    </>
  );
};
