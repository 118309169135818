import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";

export const GuestGuard = ({ children }) => {
  const token = window.sessionStorage.getItem("authToken");

  if (!!token) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};
