import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Flex, Text } from "@mantine/core";
import { Iconn } from "../../../Iconn";
import { useIsPhone } from "../../../../hooks/useIsPhone";

export const SnrChart = ({ snrData }) => {
  const isPhone = useIsPhone();

  const data = snrData.data;

  const formattedChartData = data.map(([timestamp, value]) => {
    const date = new Date(parseInt(timestamp) * 1000);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[monthIndex];
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return {
      time: `${day} ${month}`,
      tooltipTime: `${day} ${month} ${year} ${hours}:${minutes}:${seconds}`,
      dB: parseFloat(value).toFixed(2),
    };
  });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "10px",
            border: "1px solid #ccc",
          }}
        >
          <p>{`time: ${payload[0].payload.tooltipTime}`}</p>
          <p>{`dB: ${payload[0].payload.dB}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <Flex ml={-25} direction="column">
      <ResponsiveContainer height={299} width="100%">
        <AreaChart width={530} height={299} data={formattedChartData}>
          <defs>
            <linearGradient id="snr" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#00A7E1" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#00A7E1" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            axisLine={false}
            tickLine={false}
            dataKey="time"
            tickSize={14}
            fontSize={isPhone ? 10 : 12}
          />
          <YAxis
            axisLine={false}
            tickLine={false}
            tickCount={9}
            fontSize={12}
            tickSize={8}
          />
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid vertical={false} stroke="#CCCCCC80" />
          <Area
            type="linear"
            dataKey="dB"
            stroke="#00A7E1"
            fillOpacity={1}
            fill="url(#snr)"
          />
        </AreaChart>
      </ResponsiveContainer>
      <Flex justify="center" pt={10} align="center">
        <Iconn icon="dot" stroke="#00A7E1" strokeWidth={11} />
        <Text fz="sm" c="#00A7E1" fw={500}>
          Terminal Downstream Average SNR
        </Text>
      </Flex>
    </Flex>
  );
};
