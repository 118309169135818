import { Accordion } from "@mantine/core";
import { Iconn } from "../../Iconn";
import { colors } from "../../../theme/colors";
import { AlarmTable } from "./AlarmTable";
import { EventsTable } from "./EventsTable";
import { TrafficUsageTable } from "./TrafficUsageTable";

export const TableSection = ({
  trafficUsage,
  alarmsData,
  eventsData,
  setPage,
  page,
  dataPerPage,
  selectedSeverityAlarms,
  setSelectedSeverityAlarms,
  selectedSeverityEvents,
  setSelectedSeverityEvents,
}) => {
  return (
    <>
      <Accordion mt={20} styles={{ control: { padding: "0px" } }}>
        <Accordion.Item value="traffic">
          <Accordion.Control
            icon={<Iconn icon="traffic" stroke={colors.primaryBlue} />}
          >
            Traffic Usage
          </Accordion.Control>
          <Accordion.Panel>
            <TrafficUsageTable trafficUsage={trafficUsage} />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="alarms">
          <Accordion.Control
            icon={<Iconn icon="alarm" stroke={colors.primaryBlue} />}
          >
            Alarms
          </Accordion.Control>
          <Accordion.Panel>
            <AlarmTable
              alarmsData={alarmsData}
              setPage={setPage}
              page={page}
              dataPerPage={dataPerPage}
              selectedSeverityAlarms={selectedSeverityAlarms}
              setSelectedSeverityAlarms={setSelectedSeverityAlarms}
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value="events">
          <Accordion.Control
            icon={<Iconn icon="calendar" stroke={colors.primaryBlue} />}
          >
            Events
          </Accordion.Control>
          <Accordion.Panel>
            <EventsTable
              eventsData={eventsData}
              setPage={setPage}
              page={page}
              dataPerPage={dataPerPage}
              selectedSeverityEvents={selectedSeverityEvents}
              setSelectedSeverityEvents={setSelectedSeverityEvents}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
