import { Button, Divider } from "@mantine/core";
import { colors } from "../../theme/colors";
import { Link, useLocation } from "react-router-dom";

const SidebarLinkLine = () => (
  <Divider
    orientation="vertical"
    size="md"
    color={colors.lightBlue}
    pos="absolute"
    h={44}
    right={0}
    top={0}
  />
);

export const NavBarLink = ({ link, onClick }) => {
  const { pathname } = useLocation();

  const activeRoute = pathname;

  const isActive = activeRoute.toString().includes(link.to);

  const bgColor = "#00CFFF10";
  const activeBgColor = isActive ? bgColor : "transparent";

  return (
    <Button
      radius={5}
      px="md"
      justify="start"
      component={Link}
      to={link.to}
      variant="subtle"
      bg={activeBgColor}
      fw={500}
      fz="md"
      h={50}
      leftSection={link.icon}
      pos="relative"
      onClick={onClick}
      c={colors.offWhite}
    >
      {isActive && <SidebarLinkLine />}
      {link.label}
    </Button>
  );
};
