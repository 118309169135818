export const DashboardIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-layout-dashboard"
    {...props}
  >
    <rect width={7} height={9} x={3} y={3} rx={1} />
    <rect width={7} height={5} x={14} y={3} rx={1} />
    <rect width={7} height={9} x={14} y={12} rx={1} />
    <rect width={7} height={5} x={3} y={16} rx={1} />
  </svg>
);
