import { AppShell, Burger, Image, Flex, Drawer } from "@mantine/core";
import { Outlet, useNavigate } from "react-router-dom";
import Logo from "../../asset/images/logo2.png";
import { useDisclosure } from "@mantine/hooks";
import { NavBarComponent } from "./NavBarComponent";
import { colors } from "../../theme/colors";

export const Layout = () => {
  const [opened, { toggle }] = useDisclosure();
  const navigate = useNavigate();

  return (
    <AppShell header={{ height: 70 }} padding="md" withBorder={false}>
      <AppShell.Header bg="#404040">
        <Flex px="md" justify="space-between" align="center" py={5}>
          <Burger
            color={colors.primaryBlue}
            opened={opened}
            onClick={toggle}
            size="lg"
          />
          <Image
            src={Logo}
            mah={{ base: 55, sm: 60 }}
            onClick={() => navigate("/dashboard")}
            radius={14}
          />
        </Flex>
      </AppShell.Header>

      <AppShell.Navbar>
        <Drawer
          py="md"
          opened={opened}
          onClose={toggle}
          withCloseButton={false}
          styles={{
            body: {
              backgroundColor: "#404040",
              height: "100%",
            },
          }}
        >
          <NavBarComponent onClick={toggle} />
        </Drawer>
      </AppShell.Navbar>

      <AppShell.Main p={0} pt={70}>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
};
