export const ArrowUp = (props) => (
  <svg
    width={props.size}
    height={props.size}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.5056 42.6106C39.5019 42.5813 40.2051 41.9073 40.2344 40.3249C40.2637 38.4495 39.5604 35.109 37.6557 27.9589C36.4542 23.4171 37.304 22.5085 42.7838 22.5085C47.355 22.5085 44.3369 18.6112 36.2785 14.0694C31.883 11.6077 26.2276 9.02916 25.1433 8.99972C23.766 8.97056 14.8579 14.9777 11.2829 18.3182C10.4038 19.1388 9.43663 19.9005 9.11452 20.0177C6.18412 21.1606 9.52466 23.8272 13.4514 23.4757C16.3818 23.241 16.4401 23.2413 16.4401 24.091C16.4401 26.6404 13.8029 38.2737 13.2462 40.2956C13.041 41.0282 13.5977 42.5227 15.6198 42.8157C19.1361 43.3139 25.7295 42.9915 38.5056 42.6106ZM18.169 38.6254C18.8138 31.6219 19.3119 27.3435 19.4291 24.765C19.5463 21.0143 18.8138 20.7211 16.2643 20.6919L13.2167 20.6039L15.4732 19.0508C26.9602 11.6958 25.2313 11.9887 33.1722 15.974C40.2637 19.5198 40.3809 19.637 37.0696 20.0177C34.2566 20.3401 33.6412 21.014 34.0808 23.3291C34.5204 25.6733 34.9011 30.7428 35.8097 35.3434C36.3664 38.2444 36.9231 38.8598 34.0222 39.0942C32.8793 39.1821 20.9822 39.6803 19.6051 39.5924C18.1692 39.5044 18.1104 39.2114 18.169 38.6254Z"
      fill="#333333"
    />
  </svg>
);
