import {
  Alert,
  Badge,
  Flex,
  Pagination,
  Select,
  Spoiler,
  Table,
  Text,
} from "@mantine/core";
import { Iconn } from "../../Iconn";

export const AlarmTable = ({
  alarmsData,
  setPage,
  setSelectedSeverityAlarms,
  selectedSeverityAlarms,
  dataPerPage,
}) => {
  const formatDate = (dateString) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day} ${months[monthIndex]} ${year} ${hours}:${minutes}`;
  };

  const totalPage = alarmsData.data.metaData.countTotal;

  const pageCount = Math.ceil(totalPage / dataPerPage);

  const rows = alarmsData.data.alarms.map((element, index) => (
    <Table.Tr key={index}>
      <Table.Td w="12%">{formatDate(element.startDate)}</Table.Td>
      <Table.Td w="12%">{formatDate(element.endDate)}</Table.Td>
      <Table.Td w="41%">
        <Spoiler
          maxHeight={40}
          showLabel="Show more"
          hideLabel="Hide"
          styles={{ control: { fontSize: "14px" } }}
        >
          {element.description}
        </Spoiler>
      </Table.Td>
      <Table.Td w="15%">
        <Badge
          color={
            element.severity === "critical"
              ? "#ff0000"
              : element.severity === "unknown"
              ? "#6c757d"
              : element.severity === "warning"
              ? "#ffc107"
              : "#dc3545"
          }
          variant="light"
          radius={4}
          fz="sm"
          px={4}
          py={12}
          fw={500}
          w={80}
        >
          {element.severity}
        </Badge>
      </Table.Td>
      <Table.Td w="20%">{element.alarmType}</Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      {alarmsData.data.alarms.length === 0 ? (
        <Alert
          my={10}
          variant="light"
          color="red"
          icon={<Iconn icon="x" stroke="red" />}
        >
          Alarms data is currently unavailable.
        </Alert>
      ) : (
        <>
          <Flex justify="end" mb={15} align="center" gap={10}>
            <Text fw={500}>Pick severity:</Text>
            <Select
              placeholder=" "
              defaultValue="warning"
              data={[
                { value: " ", label: "No filter" },
                { value: "unknown", label: "Unknown" },
                { value: "warning", label: "Warning" },
                { value: "error", label: "Error" },
                { value: "critical", label: "Critical" },
              ]}
              value={selectedSeverityAlarms}
              onChange={(value) => setSelectedSeverityAlarms(value)}
              styles={{ option: { fontSize: "14px" } }}
            />
          </Flex>

          <Table.ScrollContainer minWidth={900}>
            <Table
              verticalSpacing="md"
              highlightOnHover
              stickyHeaderOffset={360}
            >
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Start Date</Table.Th>
                  <Table.Th>End Date</Table.Th>
                  <Table.Th>Description</Table.Th>
                  <Table.Th>Severity</Table.Th>
                  <Table.Th>Alarm type</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
              <Table.Tfoot>
                <Table.Tr style={{ border: "none" }}>
                  <Table.Td colSpan={5}>
                    <Flex justify="center">
                      <Pagination
                        styles={{ control: { border: "none" } }}
                        total={pageCount}
                        onChange={(newPage) => setPage(newPage)}
                      />
                    </Flex>
                  </Table.Td>
                </Table.Tr>
              </Table.Tfoot>
            </Table>
          </Table.ScrollContainer>
        </>
      )}
    </>
  );
};
