import { useNavigate } from "react-router-dom";
import api from "../api";
import useSWR from "swr";

const fetcher = async () => {
  const data = await api.get("terminals/current-positions-for-all-ships");

  return data;
};

export const useGetShips = () => {
  const navigate = useNavigate();

  const { data, error } = useSWR("ships", fetcher);
  if (error && error.response && error.response.status === 401) {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
    window.location.reload();
  }
  return {
    ships: data,
    isLoading: !error && !data,
    isError: error,
  };
};
