import { DateTimePicker } from "@mantine/dates";
import { Flex, Grid, Loader, Select } from "@mantine/core";
import { TableSection } from "./TableSection";
import { Charts } from "../right-section/charts-section/Charts";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnrData } from "../../../hooks/useGetSnr";
import { useLatencyData } from "../../../hooks/useGetLatency";
import { useTransmitted } from "../../../hooks/useGetTransmitted";
import { useReceived } from "../../../hooks/useGetReceived";
import { useTrafficUsage } from "../../../hooks/useGetTrafficUsage";
import { useGetAlarms } from "../../../hooks/useGetAlarms";
import { useGetEvents } from "../../../hooks/useGetEvents";

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

export const InputSection = () => {
  const { id } = useParams();
  const [selectedLastDays, setSelectedLastDays] = useState("Last 1 day");
  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(new Date());
  const [selectedResolution, setSelectedResolution] = useState("1h");
  const [selectedSeverityAlarms, setSelectedSeverityAlarms] = useState(" ");
  const [selectedSeverityEvents, setSelectedSeverityEvents] = useState(" ");
  const [page, setPage] = useState(1);
  const dataPerPage = 5;

  const { snrData } = useSnrData(id, startDate, endDate, selectedResolution);
  const { latencyData } = useLatencyData(
    id,
    startDate,
    endDate,
    selectedResolution
  );
  const { transmittedData } = useTransmitted(
    id,
    startDate,
    endDate,
    selectedResolution
  );
  const { receivedData } = useReceived(
    id,
    startDate,
    endDate,
    selectedResolution
  );

  const { trafficUsage } = useTrafficUsage(
    id,
    startDate,
    endDate,
    selectedResolution
  );

  const { alarmsData } = useGetAlarms(
    id,
    startDate,
    page,
    dataPerPage,
    selectedSeverityAlarms
  );

  const { eventsData } = useGetEvents(
    id,
    startDate,
    page,
    dataPerPage,
    selectedSeverityEvents
  );

  useEffect(() => {
    const now = new Date();
    const start = new Date();

    switch (selectedLastDays) {
      case "Last 1 day":
        start.setDate(now.getDate() - 1);
        break;
      case "Last 7 days":
        start.setDate(now.getDate() - 7);
        break;
      case "Last 14 days":
        start.setDate(now.getDate() - 14);
        break;
      case "Last 30 days":
        start.setDate(now.getDate() - 30);
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(now);
  }, [selectedLastDays]);

  const handleSelectChange = (value) => {
    setSelectedLastDays(value);
  };

  if (
    !snrData ||
    !latencyData ||
    !transmittedData ||
    !receivedData ||
    !trafficUsage ||
    !alarmsData ||
    !eventsData
  )
    return (
      <Flex pt={50} justify="center">
        <Loader color="blue" />
      </Flex>
    );

  return (
    <>
      <Grid mt={20}>
        <Grid.Col span={{ base: 12, lg: 3 }}>
          <Select
            label="Last days"
            placeholder="Pick value"
            data={["Last 1 day", "Last 7 days", "Last 14 days", "Last 30 days"]}
            value={selectedLastDays}
            onChange={(value) => {
              handleSelectChange(value);
              setPage(1);
            }}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 3 }}>
          <DateTimePicker
            label="Pick start date and time"
            placeholder="Pick start date"
            value={startDate}
            onChange={setStartDate}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 3 }}>
          <DateTimePicker
            label="Pick end date and time"
            placeholder="Pick end date"
            value={endDate}
            onChange={setEndDate}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 3 }}>
          <Select
            label="Resolution"
            placeholder="Pick value"
            data={[
              "raw",
              "30s",
              "5min",
              "30min",
              "1h",
              "3h",
              "6h",
              "12h",
              "1day",
              "1week",
              "1month",
              "1year",
            ]}
            clearable
            value={selectedResolution}
            onChange={(value) => setSelectedResolution(value)}
          />
        </Grid.Col>
      </Grid>
      <TableSection
        trafficUsage={trafficUsage}
        alarmsData={alarmsData}
        eventsData={eventsData}
        setPage={setPage}
        page={setPage}
        dataPerPage={dataPerPage}
        selectedSeverityAlarms={selectedSeverityAlarms}
        setSelectedSeverityAlarms={setSelectedSeverityAlarms}
        selectedSeverityEvents={selectedSeverityEvents}
        setSelectedSeverityEvents={setSelectedSeverityEvents}
      />
      <Charts
        snrData={snrData}
        latencyData={latencyData}
        transmittedData={transmittedData}
        receivedData={receivedData}
      />
    </>
  );
};
