import { EmailIcon } from "../asset/icons/EmailIcon";
import { MoreVerticalIcon } from "../asset/icons/MoreVerticalIcon";
import { LogoutIcon } from "../asset/icons/LogoutIcon";
import { DashboardIcon } from "../asset/icons/DashboardIcon";
import { LockIcon } from "../asset/icons/LockIcon";
import { Ship } from "../asset/icons/Ship";
import { Dot } from "../asset/icons/Dot";
import { AlarmClock } from "../asset/icons/AlarmClock";
import { Calendar } from "../asset/icons/Calendar";
import { Chart } from "../asset/icons/Chart";
import { Live } from "../asset/icons/Live";
import { ArrowLeft } from "../asset/icons/ArrowLeft";
import { Traffic } from "../asset/icons/Traffic";
import { Pin } from "../asset/icons/Pin";
import { ArrowUp } from "../asset/icons/ArrowUp";
import { ChevronRight } from "../asset/icons/ChevronRight";
import { X } from "../asset/icons/X";

const icons = {
  menu: MoreVerticalIcon,
  logout: LogoutIcon,
  dashboard: DashboardIcon,
  email: EmailIcon,
  lock: LockIcon,
  ship: Ship,
  dot: Dot,
  alarm: AlarmClock,
  calendar: Calendar,
  chart: Chart,
  live: Live,
  arrowLeft: ArrowLeft,
  traffic: Traffic,
  pin: Pin,
  arrowUp: ArrowUp,
  chevronRight: ChevronRight,
  x: X,
};

export const Iconn = ({
  icon,
  stroke = "#333333",
  size = 22,
  strokeWidth = 1.5,
  color = "",
}) => {
  const Iconn = icons[icon];
  return (
    <Iconn
      color={color}
      stroke={stroke}
      size={size}
      strokeWidth={strokeWidth}
    />
  );
};
