export const colors = {
  gray: "#A3A3A3",
  lightGray: "#33333399",
  text: "#333",
  lightText: "#333333CC",
  lighterText: "#33333399",
  purple: "#A363FF",
  offWhite: "#E5E5E5",
  turquoise: "#00FFDB",
  primaryBlue: "#00A7E1",
  lightBlue: "#00CFFF",
  darkBlue: "#0B0B5B",
};
