import { Box, Grid } from "@mantine/core";
import { GlobalMap } from "./GlobalMap";
import { AccordionGlobalSection } from "./AccordionGlobalSection";

export const DashboardPage = ({ shipsData, fleet }) => {
  return (
    <Box px={{ base: 16, sm: 32 }} py={{ base: 16, sm: 32 }}>
      <Grid justify="space-between">
        <Grid.Col span={{ base: 12, lg: 3 }}>
          <AccordionGlobalSection fleet={fleet} />
        </Grid.Col>
        <Grid.Col span={{ base: 12, lg: 9 }}>
          <GlobalMap shipsData={shipsData} />
        </Grid.Col>
      </Grid>
    </Box>
  );
};
