import { useEffect, useState } from "react";
import axiosClient from "../api/axiosClient";
import { useNavigate } from "react-router-dom";

export const useGetAlarms = (
  id,
  startDate,
  page,
  dataPerPage,
  selectedSeverityAlarms
) => {
  const [alarmsData, setAlarmsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const token = sessionStorage.getItem("authToken");
  if (!token) throw new Error("token is not present");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const formattedStartDate = startDate
        .toISOString()
        .split(".")[0]
        .replace("T", " ");

      const url =
        `/terminals/alarms/${id}?` +
        `startDate=${encodeURIComponent(formattedStartDate)}` +
        `&page=${page}` +
        `&dataPerPage=${dataPerPage}` +
        `&severity=${selectedSeverityAlarms}`;

      try {
        const response = await axiosClient.get(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setAlarmsData(response.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          sessionStorage.clear();
          localStorage.clear();
          navigate("/");
          window.location.reload();
        } else {
          console.error("Error:", error);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id, startDate, page, dataPerPage, selectedSeverityAlarms]);

  return { alarmsData, loading };
};
