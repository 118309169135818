import { Badge, Flex, Select, Table } from "@mantine/core";
import { useState } from "react";

export const TrafficUsageTable = ({ trafficUsage }) => {
  const data = trafficUsage.data;

  const formatDate = (dateString) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day} ${months[monthIndex]} ${year} ${hours}:${minutes}`;
  };

  const rows = data.map((element, index) => (
    <Table.Tr key={index}>
      <Table.Td>{formatDate(element.from)}</Table.Td>
      <Table.Td>{formatDate(element.to)}</Table.Td>
      <Table.Td>{element.serviceName}</Table.Td>
      <Table.Td>{element.upstream.total_mb} MB</Table.Td>
      <Table.Td>{element.downstream.total_mb} MB</Table.Td>
      <Table.Td>
        {element.upstream.total_mb + element.downstream.total_mb} MB
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <>
      <Table.ScrollContainer minWidth={900}>
        <Table verticalSpacing="md" highlightOnHover striped>
          <Table.Thead>
            <Table.Tr>
              <Table.Th>From</Table.Th>
              <Table.Th>To</Table.Th>
              <Table.Th>Service</Table.Th>
              <Table.Th>Upstream Usage</Table.Th>
              <Table.Th>Downstream Usage</Table.Th>
              <Table.Th>Aggregated</Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </>
  );
};
