import { LiveData } from "./LiveData";
import { MapSection } from "./MapSection";
import { Box, Flex, Loader, Text } from "@mantine/core";
import { Iconn } from "../../Iconn";
import { colors } from "../../../theme/colors";
import CustomButton from "../../CustomButton";
import { useNavigate } from "react-router";
import { InputSection } from "../table-section/InputSection";

export const ShipDetailPage = ({ liveData, onlineTime }) => {
  const navigate = useNavigate();

  return (
    <Box p={16}>
      <Flex
        mt={16}
        gap={10}
        justify={{ base: "start", sm: "center" }}
        pb={24}
        pt={{ base: 24, sm: 0 }}
      >
        <Iconn icon="live" stroke={colors.primaryBlue} />

        <Text fw={500} fz={18}>
          Live data for
        </Text>

        {!liveData ? (
          <Flex justify="center">
            <Loader color="blue" size="xs" />
          </Flex>
        ) : (
          <Text fw={600} fz={18}>
            {liveData.data.ship_name}
          </Text>
        )}
      </Flex>

      <LiveData liveData={liveData} onlineTime={onlineTime} />
      <MapSection liveData={liveData} />

      <InputSection />

      <Flex justify="center" pt={40}>
        <CustomButton
          pl={3}
          leftSection={<Iconn icon="arrowLeft" size={24} />}
          maw={{ base: "100%", sm: 348 }}
          onClick={() => navigate("/dashboard")}
        >
          Back to dashboard
        </CustomButton>
      </Flex>
    </Box>
  );
};
