import { MantineProvider } from "@mantine/core";
import React from "react";
import { AppRoutes } from "./components/AppRoutes";
import { theme } from "./theme/theme";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export const App = () => {
  const navigate = useNavigate();

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        sessionStorage.clear();
        localStorage.clear();
        navigate("/");
      }
      return Promise.reject(error);
    }
  );

  return (
    <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
      <AppRoutes />
    </MantineProvider>
  );
};
