import { Button, Flex, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Iconn } from "../Iconn";
import { colors } from "../../theme/colors";
import { NavBarLink } from "./NavBarLink";

const data = [
  {
    label: "Dashboard",
    icon: <Iconn icon="dashboard" stroke={colors.lightBlue} />,
    to: "/dashboard",
  },
];

export const NavBarComponent = ({ onClick }) => {
  const navigate = useNavigate();

  const username = localStorage.getItem("username");

  const handleClick = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <Text pb={10} c="white" fz={24} tt="capitalize">
        Welcome {username}
      </Text>
      <Flex style={{ flex: 1 }} direction="column" justify="start" pt={10}>
        {data.map((link) => (
          <NavBarLink key={link.label} link={link} onClick={onClick} />
        ))}
      </Flex>
      <Flex
        direction="column"
        style={{ position: "fixed", bottom: 20, left: 20 }}
      >
        <Button
          p={0}
          px="md"
          justify="start"
          c="#ff0000"
          fw={500}
          fz="md"
          variant="transparent"
          leftSection={<Iconn icon="logout" stroke="red" />}
          onClick={handleClick}
        >
          Logout
        </Button>
      </Flex>
    </>
  );
};
