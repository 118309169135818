import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import { Box, Button, Flex, Image, Text } from "@mantine/core";
import ShipImage from "../../asset/images/ship.png";
import { Link } from "react-router-dom";
import { Iconn } from "../Iconn";
import { colors } from "../../theme/colors";

export const GlobalMap = ({ shipsData }) => {
  const center = [0, 0];

  const redIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/128/3138/3138846.png",
    iconSize: [38, 38],
  });

  const greenIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/128/5996/5996374.png",
    iconSize: [38, 38],
  });

  const orangeIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/128/3794/3794134.png",
    // iconUrl: "https://cdn-icons-png.flaticon.com/128/727/727606.png",
    iconSize: [35, 35],
  });

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Box pos="relative">
        <Flex gap={15} justify="center" pt={{ base: 40, sm: 0 }}>
          <Text pb="sm" fw={600} fz={35} c="cyan">
            Global map
          </Text>
          {/* <Image src={ShipImage} maw={50} mah={50} /> */}
        </Flex>

        <MapContainer
          center={center}
          zoom={1}
          style={{
            height: "510px",
            width: "100%",
            zIndex: 1,
            borderRadius: "10px",
          }}
          attributionControl={false}
        >
          <TileLayer
            attributionControl={false}
            attribution={null}
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {shipsData.map((ship) => (
            <Marker
              key={ship.terminal_id}
              position={[Number(ship.latitude), Number(ship.longitude)]}
              icon={
                ship.online === "online"
                  ? greenIcon
                  : ship.online === "offline"
                  ? redIcon
                  : orangeIcon
              }
            >
              <Popup>
                <Text fz={14} my={5} fw={600} tt="uppercase" ta="center">
                  {ship.ship_name}
                </Text>
                <Text fz={14} my={5}>
                  Latitude: {parseFloat(ship.latitude).toFixed(4)}
                </Text>
                <Text fz={14} my={5}>
                  Longitude: {parseFloat(ship.longitude).toFixed(4)}
                </Text>

                <Flex
                  style={{ cursor: "pointer" }}
                  align="center"
                  justify="center"
                >
                  <Text
                    fw={400}
                    my={0}
                    fz={12}
                    component={Link}
                    to={`/ship/${ship.terminal_id}`}
                    c={colors.primaryBlue}
                  >
                    View all details
                  </Text>
                  <Iconn
                    icon="chevronRight"
                    size={16}
                    stroke={colors.primaryBlue}
                  />
                </Flex>
              </Popup>
            </Marker>
          ))}
        </MapContainer>

        <Flex
          justify="end"
          pos="sticky"
          bottom={20}
          right={0}
          style={{ zIndex: 5 }}
        >
          <Button
            style={{
              boxShadow: " 0px 4px 40px 0px rgba(41,41,41,1)",
              zIndex: 5,
            }}
            hiddenFrom="md"
            radius="50%"
            w="60px"
            h="60px"
            p={0}
            onClick={scrollToTop}
          >
            <Iconn icon="arrowUp" size={42} />
          </Button>
        </Flex>
      </Box>
    </>
  );
};
