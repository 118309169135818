export const Dot = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size}
    height={props.size}
    viewBox="0 0 24 24"
    fill="none"
    stroke={props.color}
    strokeWidth={3}
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-dot"
    {...props}
  >
    <circle cx={12.1} cy={12.1} r={1} />
  </svg>
);
